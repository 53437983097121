.Section {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}

.Section__header {
    margin-bottom: 32px;
    color: #d63031;
}

.Section__item__titleAndPrice {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.Section__item__titleAndPrice__line {
    flex-grow: 1;
    margin-top: 6px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 2.2px;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0' y1='3' x2='30' y2='3' stroke='%23777' stroke-width='2' stroke-dashoffset='10' stroke-dasharray='3, 9, 3' /%3E%3C/svg%3E");
}

.Section__item__titleAndPrice__price {
    font-style: normal;
    font-size: 18px;
}

.Section__item__description {
    margin-bottom: 24px;
    font-style: italic;
}