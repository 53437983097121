.Footer {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-bottom: 38px;
    margin-top: -70px;
}

.Footer__signature {
    width: 200px;
    margin-bottom: 8px;
    margin-right: -61px;
}

.Footer__chefName {
    font-style: italic;
    color: #777;
    text-transform: initial;
}