@import url(https://use.typekit.net/dnx1mzn.css);
@font-face {
  font-family:proxima-nova;
  src:url(https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?subset_id=2&fvd=n4&v=3) format("woff2"),
  url(https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?subset_id=2&fvd=n4&v=3) format("woff"),
  url(https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?subset_id=2&fvd=n4&v=3) format("opentype");
  font-weight:400;
  font-style:normal;
  font-stretch:normal;
  font-display:auto;
 }
 @font-face {
  font-family:proxima-nova;
  src:url(https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?subset_id=2&fvd=n6&v=3) format("woff2"),
  url(https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?subset_id=2&fvd=n6&v=3) format("woff"),
  url(https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?subset_id=2&fvd=n6&v=3) format("opentype");
  font-weight:600;
  font-style:normal;
  font-stretch:normal;
  font-display:auto;
 }
 @font-face {
  font-family:calluna;
  src:url(https://use.typekit.net/af/bccc98/000000000000000000014868/27/l?subset_id=2&fvd=n3&v=3) format("woff2"),
  url(https://use.typekit.net/af/bccc98/000000000000000000014868/27/d?subset_id=2&fvd=n3&v=3) format("woff"),
  url(https://use.typekit.net/af/bccc98/000000000000000000014868/27/a?subset_id=2&fvd=n3&v=3) format("opentype");
  font-weight:300;
  font-style:normal;
  font-stretch:normal;
  font-display:auto;
 }
 @font-face {
  font-family:calluna;
  src:url(https://use.typekit.net/af/32f92a/000000000000000000014869/27/l?subset_id=2&fvd=n4&v=3) format("woff2"),
  url(https://use.typekit.net/af/32f92a/000000000000000000014869/27/d?subset_id=2&fvd=n4&v=3) format("woff"),
  url(https://use.typekit.net/af/32f92a/000000000000000000014869/27/a?subset_id=2&fvd=n4&v=3) format("opentype");
  font-weight:400;
  font-style:normal;
  font-stretch:normal;
  font-display:auto;
 }
 @font-face {
  font-family:calluna;
  src:url(https://use.typekit.net/af/9e56a2/00000000000000000001486d/27/l?subset_id=2&fvd=n7&v=3) format("woff2"),
  url(https://use.typekit.net/af/9e56a2/00000000000000000001486d/27/d?subset_id=2&fvd=n7&v=3) format("woff"),
  url(https://use.typekit.net/af/9e56a2/00000000000000000001486d/27/a?subset_id=2&fvd=n7&v=3) format("opentype");
  font-weight:700;
  font-style:normal;
  font-stretch:normal;
  font-display:auto;
 }
 @font-face {
  font-family:calluna;
  src:url(https://use.typekit.net/af/fb816c/00000000000000000001486a/27/l?subset_id=2&fvd=i4&v=3) format("woff2"),
  url(https://use.typekit.net/af/fb816c/00000000000000000001486a/27/d?subset_id=2&fvd=i4&v=3) format("woff"),
  url(https://use.typekit.net/af/fb816c/00000000000000000001486a/27/a?subset_id=2&fvd=i4&v=3) format("opentype");
  font-weight:400;
  font-style:italic;
  font-stretch:normal;
  font-display:auto;
 }
 @font-face {
  font-family:calluna;
  src:url(https://use.typekit.net/af/c0faa1/00000000000000000001486e/27/l?subset_id=2&fvd=i7&v=3) format("woff2"),
  url(https://use.typekit.net/af/c0faa1/00000000000000000001486e/27/d?subset_id=2&fvd=i7&v=3) format("woff"),
  url(https://use.typekit.net/af/c0faa1/00000000000000000001486e/27/a?subset_id=2&fvd=i7&v=3) format("opentype");
  font-weight:700;
  font-style:italic;
  font-stretch:normal;
  font-display:auto;
 }
 @font-face {
  font-family:baskerville-urw;
  src:url(https://use.typekit.net/af/50c03d/000000000000000077359eea/30/l?subset_id=2&fvd=n4&v=3) format("woff2"),
  url(https://use.typekit.net/af/50c03d/000000000000000077359eea/30/d?subset_id=2&fvd=n4&v=3) format("woff"),
  url(https://use.typekit.net/af/50c03d/000000000000000077359eea/30/a?subset_id=2&fvd=n4&v=3) format("opentype");
  font-weight:400;
  font-style:normal;
  font-stretch:normal;
  font-display:auto;
 }
 @font-face {
  font-family:baskerville-urw;
  src:url(https://use.typekit.net/af/cf4eb3/000000000000000077359eec/30/l?subset_id=2&fvd=n5&v=3) format("woff2"),
  url(https://use.typekit.net/af/cf4eb3/000000000000000077359eec/30/d?subset_id=2&fvd=n5&v=3) format("woff"),
  url(https://use.typekit.net/af/cf4eb3/000000000000000077359eec/30/a?subset_id=2&fvd=n5&v=3) format("opentype");
  font-weight:500;
  font-style:normal;
  font-stretch:normal;
  font-display:auto;
 }
 @font-face {
  font-family:baskerville-urw;
  src:url(https://use.typekit.net/af/3ba342/000000000000000077359ef0/30/l?subset_id=2&fvd=n7&v=3) format("woff2"),
  url(https://use.typekit.net/af/3ba342/000000000000000077359ef0/30/d?subset_id=2&fvd=n7&v=3) format("woff"),
  url(https://use.typekit.net/af/3ba342/000000000000000077359ef0/30/a?subset_id=2&fvd=n7&v=3) format("opentype");
  font-weight:700;
  font-style:normal;
  font-stretch:normal;
  font-display:auto;
 }
 @font-face {
  font-family:baskerville-urw;
  src:url(https://use.typekit.net/af/1a61de/000000000000000077359f01/30/l?subset_id=2&fvd=i4&v=3) format("woff2"),
  url(https://use.typekit.net/af/1a61de/000000000000000077359f01/30/d?subset_id=2&fvd=i4&v=3) format("woff"),
  url(https://use.typekit.net/af/1a61de/000000000000000077359f01/30/a?subset_id=2&fvd=i4&v=3) format("opentype");
  font-weight:400;
  font-style:italic;
  font-stretch:normal;
  font-display:auto;
 }
 @font-face {
  font-family:baskerville-urw;
  src:url(https://use.typekit.net/af/c3a8f3/000000000000000077359eff/30/l?subset_id=2&fvd=i5&v=3) format("woff2"),
  url(https://use.typekit.net/af/c3a8f3/000000000000000077359eff/30/d?subset_id=2&fvd=i5&v=3) format("woff"),
  url(https://use.typekit.net/af/c3a8f3/000000000000000077359eff/30/a?subset_id=2&fvd=i5&v=3) format("opentype");
  font-weight:500;
  font-style:italic;
  font-stretch:normal;
  font-display:auto;
 }
 @font-face {
  font-family:baskerville-urw;
  src:url(https://use.typekit.net/af/5139d1/000000000000000077359f03/30/l?subset_id=2&fvd=i7&v=3) format("woff2"),
  url(https://use.typekit.net/af/5139d1/000000000000000077359f03/30/d?subset_id=2&fvd=i7&v=3) format("woff"),
  url(https://use.typekit.net/af/5139d1/000000000000000077359f03/30/a?subset_id=2&fvd=i7&v=3) format("opentype");
  font-weight:700;
  font-style:italic;
  font-stretch:normal;
  font-display:auto;
 }
 @font-face {
  font-family:din-condensed-web;
  src:url(https://use.typekit.net/af/cfbead/0000000000000000000146b3/27/l?subset_id=2&fvd=n4&v=3) format("woff2"),
  url(https://use.typekit.net/af/cfbead/0000000000000000000146b3/27/d?subset_id=2&fvd=n4&v=3) format("woff"),
  url(https://use.typekit.net/af/cfbead/0000000000000000000146b3/27/a?subset_id=2&fvd=n4&v=3) format("opentype");
  font-weight:400;
  font-style:normal;
  font-stretch:normal;
  font-display:auto;
 }

 html, body {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  height: 100%;
 }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  background-color: #f2f1ef;
  color: #3e3e3e;
  padding: 32px;
  position: relative;

}

#root {
  margin: -32px;
  padding: 32px;
  background-image: url(/public/sketch_2.png);
  background-repeat: no-repeat;
  background-size: contain;
}

h2, h3, h4, li {
  font-weight: 900;
  font-family: din-condensed-web;
  text-transform: uppercase;
  color: #3e3e3e;
}

h2 {
  font-family: din-condensed-web;
}

h3 {
  font-family: baskerville-urw;
}

h1 {
  text-transform: uppercase;
  font-size: 7.5em;
  line-height: 1.2;
  letter-spacing: 16px;
  font-family: miller-display,serif;
  font-weight: 900;
}

h2 {
  font-size: 24px;
  font-size: 30px;
  letter-spacing: .15em;
}

h3 {
  font-size: 18px;
}

p {
  color: #777;
  text-transform: lowercase;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  font-family: baskerville-urw;
}

