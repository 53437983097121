.App {
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
}

.App-header {
  text-align: center;
  margin-bottom: 48px;
}

.App-header h2 {
  font-size: 27px;;
}

.App-header p {
  color: #777;
}

.Logo-text {
  letter-spacing: 6px;
}

.Logo-dot {
  letter-spacing: 0;
  font-size: 80px;
  height: 100%;
  display: block;
  line-height: 0;
  color: #d63031;
  margin: -4px;
}